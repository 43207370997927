import React from 'react';
import { makeStyles } from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 16,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.secondaryTextColor,
        '&::before': {
            color: 'inherit',
        }
    },
    rotated: {
        transform: 'rotate(180deg)',
    }
}));

export const Icon = (props) => {
    const {
        name,
        className,
        rotated
    } = props;
    const classes = useStyles();

    return <span
        className={clsx(
            `ecom-icon ecom-icon-${name}`, 
            classes.root,
            className,
            { [classes.rotated]: rotated })}
    />
}