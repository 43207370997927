import _ from 'lodash';
import moment from 'moment';
import stringify from 'qs-stringify';
import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';

import { getJwt } from 'app/crud/auth.crud';
import { postRequestWrapper, getHeaders, request, serialize } from 'app/utils/fetch';
import { notify } from 'app/Services/app/notificationService';

import { actions as authActions } from '../auth/actions';
import { actions as frontendAnalyticsActions } from '../frontend-analytics/actions';
import { actionTypes } from './actionTypes';
import { actions } from './actions';

export function* watchProjectsSaga() {
  yield takeLatest(actionTypes.AddProject, apiAddProjectSaga);
  yield takeLatest(actionTypes.FetchProjects, apiFetchProjectsSaga);
  yield takeLatest(actionTypes.FetchProjectDetail, apiFetchProjectDetailSaga);
  yield takeLatest(actionTypes.TrackASIN, apiTrackASINSaga);
  yield takeLatest(actionTypes.TrackKeyword, apiTrackKeywordSaga);
  yield takeLatest(actionTypes.FetchRankTracker, apiFetchRankTrackerSaga);
  yield takeLatest(actionTypes.AddAsinToExistProject, apiAddAsinToExistProjectSaga);
  yield takeEvery(actionTypes.DeleteProject, apiDeleteProjectSaga);
  yield takeLatest(actionTypes.AddAsins, apiAddAsinsSaga);
  yield takeLatest(actionTypes.AddAsinToProject, apiAddAsinToProjectSaga);
  yield takeLatest(actionTypes.SetPrimaryAsin, apiSetPrimaryAsinSaga);
  yield takeLatest(actionTypes.FetchRankTrackerData, apiFetchRankTrackerDataSaga);
  yield takeLatest(actionTypes.AddKeywordTrackerData, apiAddKeywordTrackerForDataSaga);
  yield takeLatest(actionTypes.FetchParticularKeywordData, apiFetchParticularKeywordDataSaga);
  yield takeLatest(actionTypes.DeleteKeywordTrackerData, apiDeleteKeywordTrackerDataSaga);
  yield takeLatest(actionTypes.UpdateProject, apiUpdateProjectSaga);
  yield takeLatest(actionTypes.FetchProjectDetails, apiFetchProjectDetailsSaga);
  yield takeLatest(actionTypes.DeleteAsinFromProject, apiDeleteAsinFromProjectSaga);
  yield takeLatest(actionTypes.FetchKeywordsData, apiFetchKeywordsDataSaga);
  yield takeLatest(actionTypes.AddKeywordToExistProject, apiAddKeywordToExistProjectSaga);
  yield takeLatest(actionTypes.AddKeywordToProject, apiAddKeywordToProjectSaga);
  yield takeLatest(actionTypes.FetchSeasonalityData, apiFetchSeasonalityDataSaga);
  yield takeLatest(actionTypes.FetchRelevantKeywords, apiFetchRelevantKeywordsSaga);
  yield takeLatest(actionTypes.AddKeywordAndAsinToProject, apiAddKeywordAndAsinToProjectSaga);
  // yield takeLatest(actionTypes.DetachTag, apiDetachTagSaga);
  // yield takeLatest(actionTypes.AttachTag, apiAttachTagSaga);
  yield takeEvery(actionTypes.UpdateProjectItemTags, apiUpdateProjectItemTagsSaga);

  yield takeEvery(actionTypes.AttachDetachProjectTags, apiAttachDetachProjectTagsSaga);

  yield takeEvery(actionTypes.FetchASINOverview, apiFetchASINOverviewSaga);
  yield takeEvery(actionTypes.FetchKeywordRanks, apiFetchKeywordRanksSaga);
  yield takeEvery(actionTypes.FetchCurrentRanks, apiFetchCurrentRanksSaga);
  yield takeEvery(actionTypes.FetchBSRRanks, apiFetchBSRRanksSaga);
  yield takeLatest(actionTypes.FetchProjectAsinNotes, apiFetchProjectAsinNotesSaga);
  yield takeLatest(actionTypes.AddProjectAsinNote, apiAddProjectAsinNoteSaga);
  yield takeLatest(actionTypes.UpdateProjectAsinNote, apiUpdateProjectAsinNoteSaga);
  yield takeLatest(actionTypes.DeleteProjectAsinNote, apiDeleteProjectAsinNoteSaga);
}

function* apiAddProjectSaga(action) {
  yield put(actions.setIsGeneratingProject(true));
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects/create', token, requestPayload);
  if (apiRes) {
    yield put(actions.generatingSuccess(apiRes.data || apiRes));
    if (_.isFunction(action.cb)) {
      action.cb(apiRes?.data);
    }
  }
  yield put(authActions.fetchUserDetails());
  yield put(actions.setIsGeneratingProject(false));
}

function* apiFetchProjectsSaga(action) {
  yield put(actions.setLoading(true));
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects', token, requestPayload);
  if (apiRes) {
    yield put(actions.setProjects(apiRes.data));
  }
  yield put(actions.setLoading(false));
}

export const selectUserSelected = (state) => state.auth.user;

function* apiFetchProjectDetailSaga(action) {
  yield put(actions.setLoadingDetail(true));
  // const token = yield getJwt();
  const { project_id, no_time_counting, ...requestPayload } = action.payload;
  const selectedUserSub = _.get(action, 'payload.selectedUserSub', null) || requestPayload.selectedUserSub;
  const path = `/projects/${project_id}/asins`;
  requestPayload.selectedUserSub = selectedUserSub;

  const userSelected = yield select(selectUserSelected);
  const action_id = Math.floor(Math.random() * (99999999999999 - 10 ** 12 + 1) + 10 ** 12);

  const dataLogging = {
    user_id: userSelected.sub,
    email: userSelected.email,
    project_id: project_id,
    type: 'asins',
    page: 'projects',
    action: 'asins',
    action_id: action_id,
  };
  if (!no_time_counting) {
    yield put(frontendAnalyticsActions.addFrontendAnalytics(dataLogging));
  }
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(path, { params: requestPayload }));
  if (apiRes) {
    yield put(actions.updateProjectDetail(apiRes.data));
    const dataLoggingUpdate = { action_id };
    if (!no_time_counting) {
      yield put(frontendAnalyticsActions.updateFrontendAnalytics(dataLoggingUpdate));
    }
  }
  yield put(actions.setLoadingDetail(false));
}

function* apiTrackASINSaga(action) {
  // yield put(actions.setLoading(true));
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects/track-asin', token, requestPayload);
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.trackASINSuccess(requestPayload));
  }
  // yield put(actions.setLoading(false));
}

function* apiTrackKeywordSaga(action) {
  const token = yield getJwt();
  const requestPayload = action.payload;
  yield put(actions.trackSuccess(false));
  const apiRes = yield postRequestWrapper('/projects/track-keyword', token, requestPayload);
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.trackKeywordSuccess(requestPayload));
  }
  yield put(authActions.fetchUserDetails());
  yield put(actions.trackSuccess(true));
}

function* apiFetchRankTrackerSaga(action) {
  // yield put(actions.disableSendButtonState());
  const selectedUserSub = _.get(action, 'payload.sub', null);
  const path = selectedUserSub ? `/rank-tracker?selectedUserSub=${selectedUserSub}` : '/rank-tracker';
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(
      actions.updateRankTracker(apiRes.data),
    );
  } else {
    yield put(actions.rowClicked(null));
  }
  // yield put(actions.disableSendButtonState(false));
}

function* apiAddAsinToExistProjectSaga(action) {
  yield put(actions.addingToProject(true));
  yield put(actions.setIsGeneratingProject(true));
  yield put(actions.setAsinMessage(''));
  yield put(actions.setInvalidAsin([]));
  yield put(actions.addAsinToProjectSuccess(false));
  yield put(actions.setVariants([]));
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects/add-asin', token, requestPayload);
  if (apiRes) {
    const updateData = { ...apiRes.data, project_id: requestPayload.project_id };
    yield put(actions.updateAsins(updateData));
    yield put(actions.addAsinToProjectSuccess(true));
    if (_.isFunction(action.cb)) {
      action.cb(apiRes.status === 'FAILED' ? apiRes.message : null, apiRes.data);
    }
  }
  // yield put(actions.setIsGeneratingProject(false));
  yield put(actions.addingToProject(false));
  yield put(authActions.fetchUserDetails());
}

function* apiDeleteProjectSaga(action) {
  yield put(actions.setIsGeneratingProject(true));
  const requestPayload = action.payload;
  const path = '/projects';
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).delete(path, { data: stringify(requestPayload) }));

  if (apiRes) {
    yield put(actions.deleteProjectSuccess(apiRes.data));
    if (_.isFunction(action.cb)) {
      action.cb();
    }
  }
  yield put(authActions.fetchUserDetails());
  yield put(actions.setIsGeneratingProject(false));
}

function* apiAddAsinsSaga(action) {
  yield put(actions.addingToProject(true));
  yield put(actions.setIsGeneratingProject(true));
  yield put(actions.setAsinMessage(''));
  yield put(actions.setInvalidAsin([]));
  // yield put(actions.addAsinToProjectSuccess(false));
  yield put(actions.setVariants([]));
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects/add-asin', token, requestPayload);
  if (apiRes) {
    if (apiRes.status === 'FAILED' && apiRes.message === 'Exceeded your limit for total number of tracked asins') {
      yield put(actions.updateAsins(apiRes));
    } else {
      yield put(actions.updateAsins(apiRes.data));
      if (_.isFunction(action.cb)) {
        action.cb(apiRes.data);
      }
      // yield put(actions.addAsinToProjectSuccess(true));
    }
  }
  yield put(authActions.fetchUserDetails());
  // yield put(actions.setIsGeneratingProject(false));
  yield put(actions.addingToProject(false));
}

function* apiAddAsinToProjectSaga(action) {
  const token = yield getJwt();
  const apiRes = yield postRequestWrapper('/projects/create', token, {
    name: action.payload.name,
    ASIN: action.payload.ASIN,
    exactAsin: action.payload.exactAsin || false,
    marketplace: action.payload.marketplace || 'US',
  });
  yield put(actions.generatingSuccess(apiRes.data));
  if (apiRes.data.message === 'Not unique project name') {
    yield put(actions.addAsinToProjectSuccess(false));
    if (_.isFunction(action.cb)) {
      action.cb(apiRes.data.message, null);
    }
  } else {
    const requestPayload = {
      project_id: apiRes.data.project.project_id,
      ASINs: action.payload.ASINs,
    };
    yield put(authActions.fetchUserDetails());
    // const apiAsinRes = yield postRequestWrapper("/projects/add-asin", token, requestPayload);
    // if (apiAsinRes) {
    //   yield put(actions.addAsinToProjectSuccess(true));
    // }
    yield put(actions.addAsinToExistProject(requestPayload, action.cb));
    // yield put(actions.addingToProject(false));
  }
}

function* apiSetPrimaryAsinSaga(action) {
  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper('/projects/primary-asin', token, requestPayload);
  if (apiRes) {
    yield put(actions.fetchProjectDetail(requestPayload));
  }
}

function* apiFetchRankTrackerDataSaga(action) {
  yield put(actions.setLoadingRanksForProject(true));
  const asin = action.payload.asin;
  const { projectId, selectedUserSub, ...params } = action.payload;

  if (selectedUserSub) {
    params['selectedUserSub'] = selectedUserSub;
  }
  const path = `/projects/${projectId}/ranks/by-asin`;
  const headers = yield getHeaders();

  const userSelected = yield select(selectUserSelected);
  const action_id = Math.floor(Math.random() * (99999999999999 - 10 ** 12 + 1) + 10 ** 12);

  const dataLogging = {
    user_id: userSelected.sub,
    email: userSelected.email,
    project_id: projectId,
    asin,
    type: 'rank-tracker',
    page: 'projects',
    action: 'rank-tracker',
    action_id: action_id,
  };
  if (!action.payload.no_time_counting) {
    yield put(frontendAnalyticsActions.addFrontendAnalytics(dataLogging));
  }
  const apiRes = yield call(() => request(headers).get(path, { params }));
  if (apiRes) {
    yield put(actions.updateRankTrackerData(apiRes.data));
    if (!action.payload.no_time_counting) {
      const dataLoggingUpdate = { action_id };
      yield put(frontendAnalyticsActions.updateFrontendAnalytics(dataLoggingUpdate));
    }
  }
  yield put(actions.setLoadingRanksForProject(false));
}

function* apiAddKeywordTrackerForDataSaga(action) {
  yield put(actions.addingToProject(true));
  // yield put(actions.addKeywordToProjectSuccess(false));
  const trackRank = action.payload.trackRank;
  const requestPayload = {
    project_id: action.payload.project_id,
    keywords: action.payload.newKeywords,
    marketplace: action.payload.marketplace,
    trackRank,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).post('/projects/add-keyword', stringify(requestPayload)));
  if (apiRes) {
    if (apiRes.status === 'OK') {
      notify.success('New keywords have been added');
    }
    yield put({
      type: actionTypes.FetchKeywordsData,
      payload: {
        projectId: action.payload.project_id,
        selectedUserSub: action.payload.selectedUserSub,
      },
    });
    if (trackRank) {
      yield put({
        type: actionTypes.FetchRankTrackerData,
        payload: {
          asin: action.payload.asin,
          projectId: action.payload.project_id
        }
      });
    }
    // yield put({
    //   type: actionTypes.FetchKeywordsData,
    //   payload: {
    //     projectId: action.payload.project_id
    //   }
    // });
    // yield put(actions.addKeywordToProjectSuccess(true));
  }
  yield put(authActions.fetchUserDetails());
  yield put(actions.addingToProject(false));
}

function* apiFetchParticularKeywordDataSaga(action) {
  yield put(actions.setLoadingKeywordForProject(action.payload.keyword));
  const headers = yield getHeaders();
  const ASIN = action.payload.ASIN;
  const projectId = action.payload.projectId;
  const selectedUserSub = _.get(action, 'payload.selectedUserSub', null);
  const keyword = action.payload.keyword;
  let path = `/projects/${projectId}/ranks/item`;
  const params = { ASIN };
  if (keyword) {
    params.keyword = keyword;
  }
  if (selectedUserSub) {
    params.selectedUserSub = selectedUserSub;
  }
  path += `?${serialize(params)}`;
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes) {
    // Set oldest date in table content
    yield put(actions.updateKeywordRankValuesData(apiRes.data));
    if (_.get(apiRes, 'data.agg_date_with_timezone', '') !== '') {
      const organicDate = _.get(apiRes, 'data.agg_date_with_timezone', '').split(',')[0].slice(0, 10);
      if (_.get(apiRes, 'data.agg_date_with_timezone_sponsored', '') !== '') {
        const sponsoredDate = _.get(apiRes, 'data.agg_date_with_timezone_sponsored', '').split(',')[0].slice(0, 10);
        if (moment(organicDate) < moment(sponsoredDate)) {
          yield action.payload.onSuccess(organicDate);
        } else {
          yield action.payload.onSuccess(sponsoredDate);
        }
      } else {
        yield action.payload.onSuccess(organicDate);
      }
    } else {
      yield action.payload.onSuccess();
    }
  }
  yield put(actions.setLoadingKeywordForProject(''));
}

function* apiDeleteKeywordTrackerDataSaga(action) {
  yield put(authActions.disableSendButtonState());
  const { handleFetchRankTracker, ...requestPayload } = action.payload;
  const keywords = action.payload.keywords;
  const projectId = action.payload.projectId;
  const path = `/projects/${projectId}/keyword`;
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).delete(path, { data: stringify(requestPayload) }));
  if (apiRes) {
    yield put(actions.removeKeywordTrackerData({
      keywords,
      projectId,
    }));
    yield put(actions.fetchKeywordsData({ projectId }));
    if (handleFetchRankTracker) {
      handleFetchRankTracker();
    }
  }
  yield put(authActions.fetchUserDetails());
  yield put(authActions.disableSendButtonState(false));
}

function* apiUpdateProjectSaga(action) {
  yield put(actions.setUpdatedProjectId(action.payload.project_id));
  yield put(actions.setIsGeneratingProject(true));
  const requestPayload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).put('/projects/update', stringify(requestPayload)));
  if (apiRes) {
    yield put(actions.updateProjectSuccess(apiRes.data));
  }
  yield put(actions.setUpdatedProjectId(null));
}

function* apiFetchProjectDetailsSaga(action) {
  const token = yield getJwt();
  const requestPayload = action.payload;
  const selectedUserSub = _.get(action, 'payload.sub', null) || requestPayload.selectedUserSub.sub;
  const path = selectedUserSub ? `/projects?selectedUserSub=${selectedUserSub}` : '/projects';
  const apiRes = yield postRequestWrapper(path, token, requestPayload);
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updateResultsProject(apiRes.data));
  }
}

function* apiDeleteAsinFromProjectSaga(action) {
  yield put(authActions.disableSendButtonState());
  const requestPayload = action.payload;
  const asins = action.payload.asins;
  const projectId = action.payload.projectId;
  const path = `/projects/${projectId}/asin`;
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).delete(path, { data: stringify(requestPayload) }));
  if (apiRes) {
    yield put(actions.removeAsinFromProject({ asins }));
  }
  yield put(actions.fetchProjectDetail({
    project_id: action.payload.projectId,
    primary_asin: action.payload.primary_asin,
  }));
  yield put(actions.fetchProjects());
  yield put(authActions.fetchUserDetails());
  yield put(authActions.disableSendButtonState(false));
}

function* apiFetchKeywordsDataSaga(action) {
  yield put(actions.setLoadingProductForProject(true));
  const { projectId, selectedUserSub, ...filterQuery } = action.payload;
  const path = selectedUserSub ? `/projects/${projectId}/keywords?selectedUserSub=${selectedUserSub}` : `/projects/${projectId}/keywords`;
  const headers = yield getHeaders();

  const userSelected = yield select(selectUserSelected);
  const action_id = Math.floor(Math.random() * (99999999999999 - 10 ** 12 + 1) + 10 ** 12);

  const dataLogging = {
    user_id: userSelected.sub,
    email: userSelected.email,
    project_id: projectId,
    type: 'keywords',
    page: 'projects',
    action: 'keywords',
    action_id: action_id,
  };
  if (!action.payload.no_time_counting) {
    yield put(frontendAnalyticsActions.addFrontendAnalytics(dataLogging));
  }
  const apiRes = yield call(() => request(headers).get(path, { params: filterQuery }));
  if (apiRes) {
    yield put(actions.updateKeywordsData(apiRes.data));
    if (!action.payload.no_time_counting) {
      const dataLoggingUpdate = {
        action_id: action_id,
      };
      yield put(frontendAnalyticsActions.updateFrontendAnalytics(dataLoggingUpdate));
    }
  }
  yield put(actions.setLoadingProductForProject(false));
}

function* apiAddKeywordToExistProjectSaga(action) {
  yield put(actions.addingToProject(true));
  yield put(actions.addKeywordToProjectSuccess(false));
  const trackRank = action.payload.trackRank;
  const requestPayload = {
    project_id: action.payload.project_id,
    keywords: action.payload.newKeywords,
    marketplace: action.payload.marketplace,
    trackRank,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).post('/projects/add-keyword', stringify(requestPayload)));
  if (apiRes) {
    yield put(actions.addKeywordToProjectSuccess(true));
    if (_.isFunction(action.cb)) {
      action.cb(apiRes.status === 'FAILED' ? apiRes.message : null, { keywords: apiRes.data });
    }
  }
  yield put(authActions.fetchUserDetails());
  yield put(actions.addingToProject(false));
}

function* apiAddKeywordToProjectSaga(action) {
  yield put(actions.addingToProject(true));
  const token = yield getJwt();
  const apiRes = yield postRequestWrapper('/projects/create', token, {
    name: action.payload.name,
    ASIN: action.payload.ASIN,
    exactAsin: action.payload.exactAsin || false,
    marketplace: action.payload.marketplace || 'US',
  });
  if (apiRes.data.message === 'Not unique project name') {
    yield put(actions.generatingSuccess(apiRes.data));
    yield put(actions.addKeywordToProjectSuccess(false));
    if (_.isFunction(action.cb)) {
      action.cb(apiRes.data.message, null);
    }
  } else {
    yield put(actions.generatingSuccess(apiRes.data));
    yield put(actions.updateKeyword({
      project_id: apiRes.data.project.project_id,
      keywords: action.payload.newKeywords,
    }));
    const trackRank = action.payload.trackRank;
    const requestPayload = {
      project_id: apiRes.data.project.project_id,
      keywords: action.payload.newKeywords,
      marketplace: apiRes.data.project.marketplace,
      trackRank,
    };
    const apiKeywordRes = yield postRequestWrapper('/projects/add-keyword', token, requestPayload);
    if (apiKeywordRes) {
      yield put(actions.addKeywordToProjectSuccess(true));
    }
    yield put(authActions.fetchUserDetails());
    yield put(actions.addingToProject(false));
  }
}

function* apiFetchSeasonalityDataSaga(action) {
  yield put(actions.setLoadingProductForProject(true));
  const projectId = action.payload.projectId;
  const selectedUserSub = _.get(action, 'payload.selectedUserSub', null);
  const rangeType = action.payload.rangeType;
  const path = selectedUserSub ? `/projects/${projectId}/seasonality?selectedUserSub=${selectedUserSub}&rangeType=${rangeType}` : `/projects/${projectId}/seasonality?rangeType=${rangeType}`;
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes) {
    yield put(actions.updateSeasonalityData(apiRes.data));
  }
  yield put(actions.setLoadingProductForProject(false));
}

function* apiFetchRelevantKeywordsSaga(action) {
  yield put(authActions.disableSendButtonState());
  yield put(actions.clearRelevantKeywords());
  const token = yield getJwt();
  const requestPayload = {
    arASINs: action.payload.arASINs,
    project_id: action.payload.project_id,
    marketplace: action.payload.marketplace,
  };
  const apiRes = yield postRequestWrapper('/relevant-keywords', token, requestPayload);
  if (apiRes) {
    yield put(actions.updateRelevantKeywords(apiRes));
  }
  yield put(authActions.disableSendButtonState(false));
}

function* apiAddKeywordAndAsinToProjectSaga(action) {
  yield put(actions.addingToProject(true));
  const token = yield getJwt();
  const trackRank = action.payload.trackRank;
  const requestPayload = {
    keywords: action.payload.newKeywords,
    trackRank,
  };
  const requestPayloadAsin = {
    ASINs: action.payload.ASINs,
  };
  if (!action.payload.project_id) {
    const apiRes = yield postRequestWrapper('/projects/create', token, {
      name: action.payload.name,
      ASIN: action.payload.ASIN,
      exactAsin: action.payload.exactAsin || false,
      marketplace: action.payload.marketplace || 'US',
    });
    if (apiRes.data.message === 'Not unique project name') {
      yield put(actions.generatingSuccess(apiRes.data));
      yield put(actions.addKeywordAndAsinToProjectSuccess(false));
      if (_.isFunction(action.cb)) {
        action.cb(apiRes.data.message, null);
      }
      return;
    } else if (apiRes.data.project) {
      yield put(actions.generatingSuccess(apiRes.data));
    }
    requestPayload.project_id = apiRes.data.project.project_id;
    requestPayload.marketplace = apiRes.data.project.marketplace;
    requestPayloadAsin.project_id = apiRes.data.project.project_id;
  } else {
    requestPayload.project_id = action.payload.project_id;
    requestPayload.marketplace = action.payload.marketplace;
    requestPayloadAsin.project_id = action.payload.project_id;
  }

  const errors = [];
  let data = {};
  let hasSuccess = false;
  const apiKeywordRes = yield postRequestWrapper('/projects/add-keyword', token, requestPayload);
  if (apiKeywordRes) {
    if (trackRank) {
      yield put(actions.updateKeyword({
        project_id: requestPayloadAsin.project_id,
        keywords: action.payload.newKeywords,
      }));
    }
    if (_.isFunction(action.cb)) {
      if (apiKeywordRes.status === 'FAILED') {
        errors.push(apiKeywordRes.message);
      } else {
        hasSuccess = true;
        data = { ...data, keywords: apiKeywordRes.data };
      }
    }
  }

  yield put(actions.setIsGeneratingProject(true));
  yield put(actions.setAsinMessage(''));
  yield put(actions.setInvalidAsin([]));
  yield put(actions.setVariants([]));
  const apiAsinRes = yield postRequestWrapper('/projects/add-asin', token, requestPayloadAsin);
  if (apiAsinRes) {
    const updateData = { ...apiAsinRes.data, project_id: requestPayloadAsin.project_id };
    yield put(actions.updateAsins(updateData));
    if (_.isFunction(action.cb)) {
      if (apiAsinRes.status === 'FAILED') {
        errors.push(apiAsinRes.message);
      } else {
        hasSuccess = true;
        data = { ...data, ...apiAsinRes.data };
      }
    }
  }
  yield put(actions.addKeywordAndAsinToProjectSuccess(true));
  yield put(authActions.fetchUserDetails());
  yield put(actions.addingToProject(false));
  if (_.isFunction(action.cb)) {
    if (errors.length) {
      errors.forEach(error => action.cb(error, null));
    }
    if (hasSuccess) {
      action.cb(null, data);
    }
  }
}

// function* apiDetachTagSaga(action) {
//   const requestPayload = {
//     project_id: action.payload.projectId,
//     tags: action.payload.tags,
//   };
//   const { keywords, asins } = action.payload;
//   keywords ? requestPayload.keywords = keywords : requestPayload.asins = asins;
//   const token = yield getJwt();
//   const apiRes = yield postRequestWrapper('/keyword-tags/detach', token, requestPayload);
//   if (apiRes && apiRes.status === 'OK') {
//     yield put(actions.updateTagsForKeyword(apiRes.data));
//   }
// }

// function* apiAttachTagSaga(action) {
//   const requestPayload = {
//     project_id: action.payload.projectId,
//     tags: action.payload.tags,
//   };
//   const { keywords, asins } = action.payload;
//   keywords ? requestPayload.keywords = keywords : requestPayload.asins = asins;
//   const token = yield getJwt();
//   const apiRes = yield postRequestWrapper('/keyword-tags/attach', token, requestPayload);
//   if (apiRes && apiRes.status === 'OK') {
//     yield put(actions.updateTagsForKeyword(apiRes.data));
//   }
// }

function* apiUpdateProjectItemTagsSaga(action) {
  const items = action.payload.items;
  const token = yield getJwt();

  if (items.length > 0) {
    const apiRes = yield postRequestWrapper('/keyword-tags/attach', token, action.payload);
    yield put(actions.updateTagsForKeyword(apiRes && apiRes.data));
  }

  if (_.isFunction(action.cb)) {
    action.cb(null);
  }
}


function* apiAttachDetachProjectTagsSaga(action) {
  const requestPayload = {
    project_id: action.payload.projectId,
  };
  const token = yield getJwt();

  let apiProjectAttachRes = null;
  // if (action.payload.addedProjectTags?.length) {
  if (Array.isArray(action.payload.projectId)) {
    apiProjectAttachRes = yield postRequestWrapper('/project-tags/attach-tags-multiple', token, {
      ...requestPayload,
      tags: action.payload.addedProjectTags,
    });
  } else {
    apiProjectAttachRes = yield postRequestWrapper('/project-tags/attach-tags', token, {
      ...requestPayload,
      tags: action.payload.addedProjectTags,
    });
  }
  // }

  if (apiProjectAttachRes && apiProjectAttachRes.status === 'OK') {
    yield put(actions.updateTagsForProject(apiProjectAttachRes && apiProjectAttachRes.data));
  }
  if (_.isFunction(action.cb)) {
    action.cb(null);
  }
}

function* apiFetchASINOverviewSaga(action) {
  const project_id = action.payload.project_id;

  let requestPayload = {
    asin: action.payload.asin,
  };
  if (action.payload.startDate && action.payload.endDate) {
    requestPayload = { ...requestPayload, startDate: action.payload.startDate, endDate: action.payload.endDate };
  }
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/projects/${project_id}/asin-overview?${serialize(requestPayload)}`),
  );
  if (apiRes.data) {
    yield put(actions.fetchASINOverviewSuccesses(apiRes.data));
  }
}

function* apiFetchKeywordRanksSaga(action) {
  yield put(actions.setLoadingKeywordRanks(true));
  const project_id = action.payload.projectId;
  const keyword = action.payload.keyword;
  const from = action.payload.from;
  const to = action.payload.to;
  const headers = yield getHeaders();
  const path = `/projects/${project_id}/ranks/by-keyword?keyword=${keyword}&from=${from}&to=${to}`;
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.fetchKeywordRanksSuccesses(apiRes.data));
  }
  yield put(actions.setLoadingKeywordRanks(false));
}

function* apiFetchCurrentRanksSaga(action) {
  yield put(actions.setLoadingRanksForProject(true));
  const project_id = action.payload.projectId;
  const ASINs = action.payload.ASINs;
  const headers = yield getHeaders();
  const path = `/projects/${project_id}/current-ranks?ASINs=${ASINs}`;
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.fetchCurrentRanksSuccesses(apiRes.data));
  }
  yield put(actions.setLoadingRanksForProject(false));
}

function* apiFetchBSRRanksSaga(action) {
  yield put(actions.setLoadingRanksForProject(true));
  const project_id = action.payload.projectId;
  const from = action.payload.from;
  const to = action.payload.to;
  const headers = yield getHeaders();
  const path = `/projects/${project_id}/asin-bsrs?from=${from}&to=${to}`;
  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.fetchBSRRanksSuccesses(apiRes.data));
  }
  yield put(actions.setLoadingRanksForProject(false));
}

function* apiFetchProjectAsinNotesSaga(action) {
  yield put(actions.setLoadingProjectAsinNotes(true));
  const headers = yield getHeaders();
  const { project_id, date_from, date_to, asin } = action.payload;
  const path = `/project-asin-notes?project_id=${project_id}&date_from=${date_from}&date_to=${date_to}&asin=${asin}`;

  const apiRes = yield call(() => request(headers).get(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updateProjectAsinNotes(apiRes.data.projectAsinNotes));
  }
  yield put(actions.setLoadingProjectAsinNotes(false));
}

function* apiAddProjectAsinNoteSaga(action) {
  const headers = yield getHeaders();
  const path = `/project-asin-notes`;

  try {
    const apiRes = yield call(() => request(headers).post(path, stringify(action.payload)));
    if (apiRes && apiRes.status === 'OK') {
      if (_.isFunction(action.cb)) {
        action.cb(null); // Success case - no error
      }
    } else {
      if (_.isFunction(action.cb)) {
        action.cb(apiRes?.message || 'Failed to add note'); // Error case
      }
    }
  } catch (error) {
    if (_.isFunction(action.cb)) {
      action.cb(error?.message || 'Failed to add note'); // Error case
    }
  }
}

function* apiUpdateProjectAsinNoteSaga(action) {
  const headers = yield getHeaders();
  const { project_asin_note_id, ...payload } = action.payload;
  const path = `/project-asin-notes/${project_asin_note_id}`;

  try {
    const apiRes = yield call(() => request(headers).post(path, stringify(payload)));
    if (apiRes && apiRes.status === 'OK') {
      if (_.isFunction(action.cb)) {
        action.cb(null); // Success case - no error
      }
    } else {
      if (_.isFunction(action.cb)) {
        action.cb(apiRes?.message || 'Failed to update note'); // Error case
      }
    }
  } catch (error) {
    if (_.isFunction(action.cb)) {
      action.cb(error?.message || 'Failed to update note'); // Error case
    }
  }
}

function* apiDeleteProjectAsinNoteSaga(action) {
  const headers = yield getHeaders();

  headers["Content-Type"] = "application/json";

  const path = `/project-asin-notes/${action.payload.project_asin_note_id}`;

  const requestBody = {
    project_id: action.payload.project_id,
  };

  const apiRes = yield call(() => request(headers).delete(path, {
    data: requestBody
  }));

  if (apiRes && apiRes.status === 'OK') {
    if (_.isFunction(action.cb)) {
      action.cb();
    }
  }
}
