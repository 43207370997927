export const actionTypes = {
  AddProject: '[AddProject] Action',
  SetLoading: '[SetLoading] Action',
  FetchProjects: '[FetchProjects] Action',
  SetProjects: '[SetProjects] Action',
  FetchProjectDetail: '[FetchProjectDetail] Action',
  UpdateProjectDetail: '[UpdateProjectDetail] Action',
  TrackASIN: '[TrackASIN] Action',
  TrackKeyword: '[TrackKeyword] Action',
  TrackKeywordSuccess: '[TrackKeywordSuccess] Action',
  TrackASINSuccess: '[TrackASINSuccess] Action',
  SetIsGeneratingProject: '[SetIsGeneratingProject] Action',
  GeneratingSuccess: '[GeneratingSuccess] Action',
  FetchRankTracker: '[FetchRankTracker] Action',
  UpdateRankTracker: '[UpdateRankTracker] Action',
  RowClicked: '[RowClicked] Action',
  DeleteProject: '[DeleteProject] Action',
  DeleteProjectSuccess: '[DeleteProjectSuccess] Action',
  SetLoadingDetail: '[SetLoadingDetail] Action',
  AddAsins: '[AddAsins] Action',
  UpdateAsins: '[UpdateAsins] Action',
  SetPrimaryAsin: '[SetPrimaryAsin] Action',
  AddKeywordTrackerData: '[AddKeywordTrackerData] Action',
  SetLoadingProductForProject: '[SetLoadingProductForProject] Action',
  FetchRankTrackerData: '[FetchRankTrackerData] Action',
  UpdateRankTrackerData: '[UpdateRankTrackerData] Action',
  SetLoadingKeywordForProject: '[SetLoadingKeywordForProject] Action',
  FetchParticularKeywordData: '[FetchParticularKeywordData] Action',
  UpdateKeywordRankValuesData: '[UpdateKeywordRankValuesData] Action',
  DeleteKeywordTrackerData: '[DeleteKeywordTrackerData] Action',
  RemoveKeywordTrackerData: '[RemoveKeywordTrackerData] Action',
  UpdateProject: '[UpdateProject] Action',
  UpdateProjectSuccess: '[UpdateProjectSuccess] Action',
  SetUpdatedProjectId: '[SetUpdatedProjectId] Action',
  SetAsinMessage: '[SetAsinMessage] Action',
  FetchProjectDetails: '[FetchProjectDetails] Action',
  SetLoadingProject: '[SetLoadingProject] Action',
  UpdateResultsProject: '[UpdateResultsProject] Action',
  DeleteAsinFromProject: '[DeleteAsinFromProject] Action',
  RemoveAsinFromProject: '[RemoveAsinFromProject] Action',
  SetUpdatedPrimaryAsin: '[SetUpdatedPrimaryAsin] Action',
  SetSuccessTrackRecord: '[SetSuccessTrackRecord] Action',
  SetInvalidAsin: '[SetInvalidAsin] Action',
  FetchKeywordsData: '[FetchKeywordsData] Action',
  UpdateKeywordsData: '[UpdateKeywordsData] Action',
  AddKeywordToExistProject: '[AddKeywordToExistProject] Action',
  AddKeywordToProject: '[AddKeywordToProject] Action',
  AddKeywordToProjectSuccess: '[AddKeywordToProjectSuccess] Action',
  AddAsinToExistProject: '[AddAsinToExistProject] Action',
  AddAsinToProject: '[AddAsinToProject] Action',
  AddAsinToProjectSuccess: '[AddAsinToProjectSuccess] Action',
  AddingToProject: '[AddingToProject] Action',
  FetchSeasonalityData: '[FetchSeasonalityData] Action',
  UpdateSeasonalityData: '[UpdateSeasonalityData] Action',
  SetVariants: '[SetVariants] Action',
  FetchRelevantKeywords: '[FetchRelevantKeywords] Action',
  UpdateRelevantKeywords: '[UpdateRelevantKeywords] Action',
  TrackSuccess: '[TrackSuccess] Action',
  ClearRelevantKeywords: '[ClearRelevantKeywords] Action',
  AddKeywordAndAsinToProject: '[AddKeywordAndAsinToProject] Action',
  AddKeywordAndAsinToProjectSuccess: '[AddKeywordAndAsinToProjectSuccess] Action',
  // DetachTag: '[DetachTag] Action',
  DetachProjectTag: '[DetachProjectTag] Action',
  UpdateTagsForKeyword: '[UpdateTagsForKeyword] Action',
  UpdateTagsForProject: '[UpdateTagsForProject] Action',
  UpdateProjectItemTags: '[UpdateProjectItemTags] Action',
  AttachDetachProjectTags: '[AttachDetachProjectTags] Action',
  // AttachTag: '[AttachTag] Action',
  AttachProjectTag: '[AttachProjectTag] Action',
  UpdateTagsForAllKeywords: '[UpdateTagsForAllKeywords] Action',
  DeleteTagsForAllKeywords: '[DeleteTagsForAllKeywords] Action',
  UpdateTagsForAllProjects: '[UpdateTagsForAllProjects] Action',
  DeleteTagsForAllProjects: '[DeleteTagsForAllProjects] Action',
  SetShownTags: '[SetShownTags] Action',
  SetDefaultProjectAdditionSuccesses: '[SetDefaultProjectAdditionSuccesses] Action',
  UpdateKeyword: '[UpdateKeyword] Action',
  FetchASINOverview: '[FetchASINOverview] Action',
  FetchASINOverviewSuccesses: '[FetchASINOverviewSuccesses] Action',
  SetLoadingKeywordRanks: '[SetLoadingKeywordRanks] Action',
  FetchKeywordRanks: '[FetchKeywordRanks] Action',
  FetchKeywordRanksSuccesses: '[FetchKeywordRanksSuccesses] Action',
  SetLoadingRanksForProject: '[SetLoadingRanksForProject] Action',
  FetchCurrentRanks: '[FetchCurrentRanks] Action',
  FetchCurrentRanksSuccesses: '[FetchCurrentRanksSuccesses] Action',
  FetchBSRRanks: '[FetchBSRRanks] Action',
  FetchBSRRanksSuccesses: '[FetchBSRRanksSuccesses] Action',
  SetFetchedRank: '[SetFetchedRank] Action',
  SetSearchProject: '[SetSearchProject] Action',
  SetSelectedProject: '[SetSelectedProject] Action',
  FetchProjectAsinNotes: '[FetchProjectAsinNotes] Action',
  UpdateProjectAsinNotes: '[UpdateProjectAsinNotes] Action',
  AddProjectAsinNote: '[AddProjectAsinNote] Action',
  UpdateProjectAsinNote: '[UpdateProjectAsinNote] Action',
  DeleteProjectAsinNote: '[DeleteProjectAsinNote] Action',
  SetLoadingProjectAsinNotes: '[SetLoadingProjectAsinNotes] Action',
};
